<template>
  <v-container data-test="patients">
    <v-row align="center" justify="center">
      <v-card max-width="1100" width="100%" flat class="transparent mb-0 mb-md-5">
        <v-card-title flat class="transparent">
          <div class="d-flex">
            <h1 class="headline">{{ $t('patient.patients') }}</h1>
          </div>
          <v-spacer />
          <v-btn v-if="!hidePatientCreation" depressed color="primary" class="order-md-2" @click="mostrarModal('nuevo', 0)">
            {{ $t('patient.new') }}
          </v-btn>
          <div v-if="isMobileVersion" class="flex-break" />
          <v-text-field
            v-if="!isLoading"
            v-model="search"
            filled
            rounded
            flat
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('patient.search')"
            hide-details
            class="pa-0 mt-4 mt-md-0 mr-md-2 order-md-1"
          />
        </v-card-title>

        <v-card :flat="isMobileVersion">
          <div v-if="isLoading" class="pa-5 text-center">
            <v-progress-circular width="2" color="primary" indeterminate />
          </div>

          <div v-if="!isLoading && isMobileVersion">
            <v-row v-if="filteredPatients.length > 0" dense no-gutters>
              <v-col v-for="patient in filteredPatients" :key="`patient-${patient.id}`" cols="12">
                <v-divider />
                <PatientItem
                  :patient="patient"
                  @edit-patient="editPatient(patient)"
                  @delete-patient="mostrarModal('delete', patient.id)"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col align="center" class="ma-0 pa-0">
                <v-divider />
                <div class="mt-4">{{ $t('patient.without_patients') }}</div>
              </v-col>
            </v-row>
          </div>

          <v-data-table
            v-if="!isLoading && !isMobileVersion"
            :headers="headers"
            :items="patients"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :options="{ sortBy: ['created_at'], sortDesc: [true] }"
            item-key="name"
            data-test="patients_patients-list"
          >
            <template v-slot:item="props">
              <tr data-test="patients_patient">
                <td class="clickeable" @click="abrir(props.item.url)">
                  {{ props.item.nombre }}
                </td>
                <td class="clickeable" @click="abrir(props.item.url)">
                  {{ props.item.apellidos }}
                </td>
                <td class="clickeable" @click="abrir(props.item.url)">
                  {{ props.item.dni }}
                </td>
                <td class="clickeable" @click="abrir(props.item.url)">
                  {{ props.item.created_at | moment('DD/MM/YYYY') }}
                </td>
                <td width="10%" class="text-right text-no-wrap">
                  <v-btn data-test="patient-button-edit" v-tooltip="$t('common.edit')" icon color="grey" :to="props.item.url">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    data-test="patient-button-delete"
                    v-if="permisos.eliminarPacientes && showDeletePatientButton"
                    v-tooltip="$t('common.delete')"
                    icon
                    color="grey"
                    @click="mostrarModal('delete', props.item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>

            <template slot="no-data">
              {{ $t('patient.without_patients') }}
            </template>
            <template slot="pageText" slot-scope="props">
              {{ $t('patient.patients') }} {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }}
              {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card>
        <div class="text-center py-5">
          <v-pagination v-model="page" circle :length="pageCount" @input="changePage" />
        </div>
      </v-card>
    </v-row>
    <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="title">{{ $t('patient.delete') }}</span>
        </v-card-title>
        <v-card-text>
          <p>{{ $t('patient.confirm_delete') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false">{{
            $t('common.cancel')
          }}</v-btn>
          <v-btn
            color="red darken-1 white--text"
            depressed
            @click.native="
              dialog_delete.dialog = false;
              borrarPaciente(paciente_id);
            "
            >{{ $t('common.delete') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_new.dialog" width="600" overlay persistent>
      <v-card>
        <new-patient
          v-if="dialog_new.dialog"
          :show-health-card-number="showHealthCardNumber"
          :patient-id="paciente_id"
          @close-dialog="closeDialog"
          @showSnackbar="showSnackbar"
        />
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { nameEnv, pacientesUrl, deletePacienteUrl, showDeletePatient, getHeader } from '@/config/config';
import NewPatient from '../components/ehealth/paciente/New.vue';
import PatientItem from './PatientItem';
import environment from "@/environment";
import * as Sentry from "@sentry/vue";

export default {
  components: {
    NewPatient,
    PatientItem,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    es_mobile: window.innerWidth <= 500,
    isLoading: true,
    page: 1,
    pageCount: null,
    itemsPerPage: 20,
    search: '',
    snackbar: false,
    disabled: false,
    timeout: 5000,
    mode: '',
    introduccionManual: false,
    dialog_delete: {
      name: '',
      dialog: false,
    },
    dialog_new: {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    },
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tipo: 'outlined',
    },
    headers: [
      { text: 'Nombre', align: 'left', value: 'fullName' },
      { text: 'Apellidos', align: 'left', value: 'apellidos' },
      { text: 'DNI', align: 'left', value: 'dni' },
      { text: 'Fecha Alta', align: 'left', value: 'created_at' },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    patients: [],
    filteredPatients: [],
    paciente_id: 0,
    datatable: {
      rows_per_page: 'Rows per Page',
    },
    dateFormatted: null,
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
    colorPrimario:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== ''
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['color']
        : 'primary',
    is_receptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
    permisos: {
      eliminarPacientes: !(
        nameEnv === 'medyola' &&
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 1
      ),
    },
    showHealthCardNumber: false,
    showDeletePatientButton: true,
  }),
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
      appDisplayName: state => state.app.displayName,
    }),

    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    hidePatientCreation() {
      return this.companyPermissions?.hidePatientCreation;
    },
  },

  watch: {
    page() {
      this.$scrollTo('#appRoot');
    },
    search(filter) {
      let filterResult = [...this.patients];
      if (this.search) {
        const filterRegExp = new RegExp(this.replaceSpecialChars(filter), 'i');
        filterResult = filterResult.filter(
          patient =>
            (patient.fullName && this.replaceSpecialChars(patient.fullName).match(filterRegExp)) ||
            (patient.fecha_alta && patient.fecha_alta.match(filterRegExp)) ||
            (patient.created_at && patient.created_at.match(filterRegExp)) ||
            (patient.email && patient.email.match(filterRegExp))
        );
      }
      this.filteredPatients = this.sortByCreationDate(filterResult);

      setTimeout(
        function() {
          if (this.search === filter) {
            this.page = 1;
            this.listPatients(filter);
          }
        }.bind(this),
        500
      );
    },
  },
  mounted() {
    if (typeof this.permissions.profilesFormWithInsuredNumber !== 'undefined') {
      this.showHealthCardNumber = this.permissions.profilesFormWithInsuredNumber;
    }
    this.headers[0]['text'] = this.$t('patient.first_name');
    this.headers[1]['text'] = this.$t('patient.last_name');
    this.headers[2]['text'] = this.$t('patient.document_id');
    this.headers[3]['text'] = this.$t('common.date_up');
    this.headers[4]['text'] = this.$t('common.actions');
    this.datatable.rows_per_page = this.$t('datatable.rows_per_page');
    this.listPatients('');
  },

  created() {
    this.checkIfShowDeleteButton(this.authUser.id);
    if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
      this.dialog_new.dialog = true;
    }
    const mensaje = JSON.parse(window.localStorage.getItem('mensaje_ehealth'));
    if (mensaje && mensaje.texto !== '') {
      this.alerta.texto = mensaje.texto;
      this.alerta.color = mensaje.color;
      localStorage.removeItem('mensaje_ehealth');
      this.snackbar = true;
    }
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    replaceSpecialChars(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    abrir(url) {
      this.$router.push(url);
    },

    listPatients(filter) {
      this.isLoading = filter === '';
      filter = filter === null ? '' : filter;
      this.$http
        .get(pacientesUrl + '?filter=' + filter + '&page=' + this.page + '&size=' + this.itemsPerPage, {
          headers: getHeader(),
        })
        .then(response => {
          this.patients = response.data.patients;
          this.filteredPatients = this.sortByCreationDate([...this.patients]);
          this.pageCount = response.data.meta.lastPage;
        })
        .catch(err => {
          this.$log.error(err);
          if (err.status === 401) {
            localStorage.removeItem('auth_ehealth');
            this.$router.push('Login');
          } else if (err.status === 403) {
            location.reload();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    sortByCreationDate(data) {
      return data.sort((a, b) => {
        if (!b.created_at) {
          Sentry.withScope(function (scope) {
            scope.setLevel("error");
            Sentry.captureException(new Error(`[${environment.name}] Patient ${b.id} has no created_at field`));
          });
        } else {
          return b.created_at.localeCompare(a.created_at);
        }
      });
    },

    mostrarModal(tipo, id) {
      if (tipo === 'delete') {
        this.dialog_delete.dialog = true;
      } else if (tipo === 'nuevo') {
        this.dialog_new.dialog = true;
      }
      this.paciente_id = id;
    },

    borrarPaciente(id) {
      if (this.permisos.eliminarPacientes) {
        this.$http
          .post(deletePacienteUrl, { id: this.authUser.id, patient_id: id }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200) {
              this.showAlert({ color: 'info', icon: 'mdi-alert-circle', message: this.$t('patient.delete_success') });
              this.listPatients('');
            } else {
              this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('patient.delete_error') });
            }
          })
          .catch(err => {
            this.$log.error(err);
            console.log(err);
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t(err.body) });
          });

        setTimeout(() => {
          this.alerta.texto = '';
        }, 5000);
      }
    },

    changePage() {
      this.listPatients('');
    },

    closeDialog(type) {
      if (type === 'new') {
        this.dialog_new.dialog = false;
        this.$route.name === 'NuevoPaciente' && this.$router.push('/pacientes');
      }
    },

    showSnackbar(alert) {
      this.alerta = alert;
      this.snackbar = true;
    },

    cerrarNuevoPaciente() {
      this.introduccionManual = false;
      if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
        this.$router.push('/dashboard');
      } else {
        this.dialog_new.dialog = false;
      }
    },

    checkIfShowDeleteButton(user_id) {
      this.$http
        .post(showDeletePatient, { user_id: user_id, is_receptionist: this.is_receptionist }, { headers: getHeader() })
        .then(response => {
          this.showDeletePatientButton = response.data.showDeleteButton;
        })
        .catch(err => {
          this.$log.error(err);
        });
    },
    editPatient(patient) {
      this.$router.push(patient.url);
    },
  },
};
</script>
<style scoped lang="css">
.clickeable {
  cursor: pointer;
}
.flex-break {
  flex-basis: 100%;
  height: 0;
}
</style>
