<template>
  <div id="new-patient">
    <v-toolbar flat>
      <h2 class="title">{{ $t('patient.new') }}</h2>
      <v-spacer />
      <v-btn v-tooltip="$t('common.close')" icon @click="$emit('close-dialog', 'new')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form ref="form" v-model="validForm" class="form_paciente_nuevo">
      <v-layout wrap>
        <v-flex xs12 sm6 lg6>
          <v-text-field
            data-test="patient-input-name"
            v-model="paciente.nombre"
            outlined
            filled
            dense
            type="text"
            class="mx-1"
            :label="$t('patient.first_name') + ' *'"
            :rules="rules.nameOrSurname"
          />
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <v-text-field
            data-test="patient-input-surname"
            v-model="paciente.apellidos"
            outlined
            filled
            dense
            type="text"
            class="mx-1"
            :label="$t('patient.last_name') + ' *'"
            :rules="rules.nameOrSurname"
          />
        </v-flex>
        <v-flex sm12 lg12>
          <v-identify-document
            data-test="patient-input-document-type"
            :dense="true"
            :filled="true"
            :id-document="paciente.dni"
            :id-document-type="paciente.tipoDocumento"
            class="mx-1"
            use-required
            @onError="handleIdErrors"
            @input="setIdDocument"
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-menu
            ref="fNacimiento"
            v-model="fechaNacimientoMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            :return-value.sync="paciente.fechaNacimiento"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                data-test="patient-input-birthdate"
                v-model="dateFormatted"
                outlined
                filled
                dense
                class="mx-1"
                :label="$t('patient.birthdate') + ' *'"
                :rules="rules.requiredField"
                readonly
                append-icon="mdi-calendar"
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="date"
              first-day-of-week="1"
              :locale="this.$i18n.locale"
              :min="datePickerMinDate"
              :max="new Date().toISOString().substr(0, 10)"
              no-title
              scrollable
              @input="$refs.fNacimiento.save(date)"
            />
          </v-menu>
        </v-flex>
        <v-flex sm6 lg6>
          <v-select
            data-test="patient-input-gender"
            v-model="paciente.sexo"
            outlined
            dense
            filled
            class="mx-1"
            item-text="label"
            item-value="value"
            :items="[
              {
                value: '0',
                label: $t('patient.female'),
              },
              {
                value: '1',
                label: $t('patient.male'),
              },
              {
                value: '2',
                label: $t('patient.other'),
              },
            ]"
            :placeholder="`${$t('patient.gender')}*`"
            :rules="rules.requiredField"
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            data-test="patient-input-email"
            v-model="paciente.email"
            outlined
            dense
            filled
            class="mx-1"
            :label="$t('message.email') + ' *'"
            :rules="rules.emailRequired"
            type="mail"
            required
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            data-test="patient-input-email-repeat"
            v-model="paciente.email_repeat"
            outlined
            dense
            filled
            class="mx-1"
            type="mail"
            required
            :label="$t('message.email_repeat') + ' *'"
            :rules="rules.emailMatches"
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-tel-field
            data-test="patient-input-phone"
            v-model="paciente.telefono"
            :allow-web-phone="true"
            outlined
            dense
            filled
            :required="isPhoneMandatoryForPatient"
            :rules="isPhoneMandatoryForPatient ? rules.requiredField : false"
            class="mx-1"
            v-bind="bindProps"
            @call="callEvent"
          />
        </v-flex>

        <v-flex sm6 lg6>
          <v-select
            data-test="patient-input-lang"
            v-model="paciente.idioma"
            outlined
            dense
            filled
            class="mx-1"
            :items="idiomas"
            :label="$t('common.lang')"
            menu-props="auto"
            required
            :rules="rules.requiredField"
            item-text="name"
            item-value="id"
          />
        </v-flex>
        <v-flex v-if="showPersonalDoctorAssistantAssignment" sm12 lg12 class="mb-3">
          <PersonalDoctorsSelector
            :company-id="authUser.company_docline_api_id"
            @personalDoctorSelected="personalDoctorSelected"
          />
        </v-flex>
        <v-flex v-if="showPersonalDoctorProfessionalAssignment" sm12 lg12 class="mb-3">
          <PersonalDoctorsCheckbox
            :professional-id="authUser.user.professional_docline_api_id"
            :company-id="authUser.company_docline_api_id"
            @personalDoctorChanged="personalDoctorChanged"
          />
        </v-flex>
        <v-flex v-if="showHealthCardNumber" xs12 sm6 lg6>
          <v-text-field
            data-test="patient-input-health-card-number"
            v-model="paciente.numsegsocial"
            outlined
            dense
            filled
            class="mr-2"
            :label="$t('common.numero_seg_social')"
            type="text"
            required
            :rules="rules.requiredField"
          />
        </v-flex>
        <v-flex xs12 class="text-right pb-6">
          <v-btn data-test="patient-button-cancel" color="grey darken-1" class="mr-2" text @click="$emit('close-dialog', 'new')">{{
            $t('common.cancel')
          }}</v-btn>
          <v-btn
            data-test="patient-button-create"
            depressed
            :loading="loading"
            color="primary"
            :disabled="disabled || !validForm || dniError"
            @click="validateForm"
          >
            {{ $t('common.create') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { createPacienteUrl, getHeader, nameEnv, defaultCountry } from '@/config/config';
import FormValidator from '@/mixins/FormValidation';
import VIdentifyDocument from '@/modules/VuetifyIdentityDocument/components/VIdentifyDocument';

import { datePickerMinDate } from '@/services/dateHelper';
import { setSelectorsLanguages, autogenerateEmail, isPhoneMandatoryForPatient } from '@/utils';
import { authUserService } from '@/services/auth-user';
import PersonalDoctorsSelector from '@/components/PersonalDoctors/PersonalDoctorsSelector';
import PersonalDoctorsCheckbox from '@/components/PersonalDoctors/PersonalDoctorsCheckbox';
import { useAssignmentPersonalDoctor } from '@/use/PersonalDoctors/useAssignmentPersonalDoctor';
import { mapState } from 'vuex';

export default {
  name: 'NewPatient',
  components: {
    PersonalDoctorsCheckbox,
    VIdentifyDocument,
    PersonalDoctorsSelector,
  },
  mixins: [FormValidator],
  props: {
    showHealthCardNumber: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    const { assignPersonalDoctor, deassignPersonalDoctor } = useAssignmentPersonalDoctor(
      authUser.company_docline_api_id
    );

    return {
      assignPersonalDoctor,
      deassignPersonalDoctor,
    };
  },

  data() {
    return {
      dniError: true,
      validForm: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: false,
      disabled: false,
      es_mobile: false,
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
        tipo: 'outlined',
      },
      rules: {
        emailMatches: [this._required(), this._matches(this, 'paciente.email')],
      },
      paciente: {
        valid: true,
        id: 0,
        nombre: '',
        apellidos: '',
        codigoPostal: '',
        direccion: '',
        localidad: '',
        provincia: '',
        dni: '',
        email: '',
        email_repeat: '',
        fechaNacimiento: null,
        sexo: '',
        telefono: '',
        idioma: 'es',
        tipoDocumento: 0,
        numsegsocial: '',
        phonePrefix: '',
      },
      idiomas: [
        { id: 'es', name: 'Español' },
        { id: 'en', name: 'Ingles' },
      ],
      tiposDocumento: [
        { id: '1', name: 'DNI' },
        { id: '2', name: 'NIE' },
        { id: '3', name: 'Pasaporte' },
        { id: '4', name: 'Otro' },
      ],
      fechaNacimientoMenu: false,
      date: null,
      menu: false,
      tipoDocumento: '0',
      es_dingdoc: nameEnv === 'dingdoc',
      phone: '',
      bindProps: {
        mode: 'international',
        defaultCountry: defaultCountry ? defaultCountry : 'ES',
        preferredCountries: nameEnv === 'dingdoc' ? ['ES'] : ['FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: 'Teléfono *',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },

      datePickerMinDate: datePickerMinDate(),
      isPhoneMandatoryForPatient: isPhoneMandatoryForPatient(),

      personalDoctor: null,
      isYourPersonalDoctor: false,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
    }),

    dateFormatted() {
      return this.formatDate(this.date);
    },

    showPersonalDoctorAssistantAssignment() {
      return (
        authUserService.isReceptionistRol() &&
        this.companyPermissions &&
        this.companyPermissions.personalDoctor &&
        this.companyPermissions.personalDoctor.personalDoctorAssistant
      );
    },

    showPersonalDoctorProfessionalAssignment() {
      return (
        authUserService.isProfessionalRol() &&
        this.companyPermissions &&
        this.companyPermissions.personalDoctor &&
        this.companyPermissions.personalDoctor.personalDoctorProfessional
      );
    },
  },

  watch: {
    fechaNacimientoMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
  },

  mounted() {
    this.personalizacionesEntorno();
    this.setDefaults();
  },

  methods: {
    setDefaults() {
      const f = new Date();
      f.setFullYear(f.getFullYear() - 18);
      this.paciente.fechaNacimiento = f.getFullYear() + '-' + (f.getMonth() + 1) + '-' + f.getDate();
      this.bindProps.placeholder = this.isPhoneMandatoryForPatient
        ? this.$t('patient.phone') + '*'
        : this.$t('patient.phone');
      if (defaultCountry) {
        this.paciente.pais_iso = defaultCountry;
        this.bindProps.preferredCountries.unshift(defaultCountry);
      }
    },

    handleIdErrors() {
      this.$nextTick(() => {
        this.dniError = true;
      });
    },

    setIdDocument(data) {
      this.dniError = false;
      this.paciente.dni = data.idDocument;
      this.paciente.tipoDocumento = data.idDocumentType;
    },

    callEvent(number) {
      this.$dialog.call({
        number: number,
        name: this.paciente.nombre,
      });
    },

    save(date) {
      this.$refs.menu.save(date);
    },

    personalizacionesEntorno() {
      this.idiomas[0]['name'] = this.$t('common.spanish');
      this.idiomas[1]['name'] = this.$t('common.english');
      this.tiposDocumento[2]['name'] = this.$t('patient.pasaporte');
      this.tiposDocumento[3]['name'] = this.$t('common.otro');
      this.idiomas = setSelectorsLanguages(this.idiomas);
      this.setUserLanguage();
      this.generateEmail();
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    clearString(str) {
      return str.replace(/ +/g, '').replace(/-/g, '');
    },

    validateForm() {
      this.validForm = this.$refs.form.validate();

      if (!this.validForm || this.dniError) {
        return;
      }

      this.createPatient();
    },

    async createPatient() {
      if (this.disabled) {
        return;
      }

      this.disabled = true;
      this.loading = true;

      this.paciente.fechaNacimiento = this.date;

      const phone_pre_format = this.paciente.telefono;
      if (this.paciente.telefono.length > 11) {
        const phone_prefix = this.paciente.telefono.substring(0, this.paciente.telefono.indexOf(' '));
        let phone = this.paciente.telefono.substring(this.paciente.telefono.indexOf(' '));
        phone = this.clearString(phone);
        this.paciente.telefono = phone;
        this.paciente.phonePrefix = phone_prefix;
      }

      try {
        const response = await this.$http.post(
          createPacienteUrl,
          { id: this.authUser.id, patient: this.paciente },
          { headers: getHeader() }
        );

        if (
          response.status === 200 &&
          (response.data.estado === 1 || response.data.estado === 3 || response.data.estado === 4)
        ) {
          if (this.showPersonalDoctorProfessionalAssignment) {
            if (this.isYourPersonalDoctor) {
              await this.assignPersonalDoctor(
                response.data.patient_docline_api_id,
                this.authUser.user.professional_docline_api_id
              );
            }
          } else if (this.showPersonalDoctorAssistantAssignment) {
            if (this.personalDoctor) {
              await this.assignPersonalDoctor(response.data.patient_docline_api_id, this.personalDoctor);
            }
          }

          this.alerta.texto =
            response.data.estado === 1
              ? this.$t('success.new_patient')
              : response.data.estado === 3
              ? this.$t('success.patient_exist')
              : this.$t('success.patient_exist_otro_mail');
          this.alerta.color = 'success';
          this.alerta.icono = 'mdi-account-check';
          window.localStorage.setItem('mensaje_ehealth', JSON.stringify(this.alerta));
          this.$router.push('/paciente/' + response.data.id);
        } else if (response.data.estado === 5) {
          this.alerta.texto = this.$t('patient.enviado_mensaje_vincular');
          this.alerta.color = 'info';
          this.alerta.icono = 'mdi-information';
        } else {
          this.alerta.texto =
            response.data.estado === 2
              ? this.$t('errors.existing_patient', {
                  name: this.appDisplayName,
                })
              : this.$t('errors.try_again');
          this.alerta.color = 'error';
          this.alerta.icono = 'mdi-alert-circle';
        }
      } catch (e) {
        this.alerta.color = 'error';
        if (e.data && e.data.error) {
          this.alerta.texto = this.$t(e.data.error);
        } else {
          this.alerta.texto = this.$t('errors.try_again');
        }
      } finally {
        this.$emit('showSnackbar', this.alerta);
        this.disabled = false;
        this.loading = false;
        this.paciente.telefono = phone_pre_format;
      }
    },
    setUserLanguage() {
      if (this.idiomas.length === 1) {
        this.paciente.idioma = this.idiomas[0]['id'];
      }
    },
    generateEmail() {
      this.paciente.email = autogenerateEmail();
      this.paciente.email_repeat = this.paciente.email;
    },
    personalDoctorSelected(personalDoctor) {
      this.personalDoctor = personalDoctor;
    },

    personalDoctorChanged(val) {
      this.isYourPersonalDoctor = val;
    },
  },
};
</script>

<style>
.form_paciente_nuevo {
  padding: 10px 30px;
}
.label_genero {
  margin-bottom: 0px;
}
.radio_genero {
  margin-top: 3px;
}
.titulo_modal {
  margin-top: 0px !important;
}
.vue-tel-input {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid black;
}

@media only screen and (min-width: 660px) {
  .vue-tel-input {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 660px) {
  .vue-tel-input {
    margin-bottom: 1rem;
  }
}
</style>
