<template>
  <v-card flat>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <div class="d-flex flex-row justify-space-between">
            <div class="subtitle-1 font-weight-bold">
              {{ patient.fullName }}
            </div>
            <div class="caption">
              <v-icon small light class="mr-1" />
              {{ patient.fecha_alta }}
            </div>
          </div>
        </v-col>

        <v-col class="d-flex flex-column align-self-end">
          <div v-if="patient.dni">
            {{ patient.dni }}
          </div>
          <div>
            <v-icon v-if="patient.sexo === 0" key="gender" small light class="mr-1">mdi-gender-female</v-icon>
            <v-icon v-else-if="patient.sexo === 1" key="gender" small light class="mr-1">mdi-gender-male</v-icon>
            <span v-if="age">{{ age }} {{ $t('recipe.years') }}</span>
          </div>
        </v-col>

        <v-col cols="2" align="right" class="align-self-end align-self-md-center">
          <v-btn icon small elevation="0" @click="showPatientMenu">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-bottom-sheet v-model="showMenu">
        <v-list class="pa-0 rounded-t-xl">
          <v-list-item v-for="(option, index) in menuOptions" :key="index" class="py-3" @click="option.onClick()">
            <v-list-item-title
              class="text-button text-center"
              :class="option.color ? `${option.color}--text` : ''"
              @click="option.onClick()"
            >
              {{ option.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PatientItem',
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
      menuOptions: [
        { title: `${this.$t('common.edit')} ${this.$t('common.patient').toLowerCase()}`, onClick: this.editPatient },
        { title: this.$t('common.delete'), onClick: this.deletePatient },
      ],
    };
  },
  computed: {
    age() {
      if (this.patient.fechaNacimiento) {
        return this.$moment().diff(new Date(this.patient.fechaNacimiento), 'years');
      }
      return null;
    },
  },
  methods: {
    showPatientMenu() {
      this.showMenu = true;
    },
    editPatient() {
      this.showMenu = false;
      this.$emit('edit-patient');
    },
    deletePatient() {
      this.showMenu = false;
      setTimeout(() => {
        this.$emit('delete-patient');
      }, 50);
    },
  },
};
</script>
